var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c(
        "div",
        [
          _vm._m(0),
          _c("br"),
          _c("br"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_loading,
                  expression: "is_loading",
                },
              ],
              staticClass:
                "text-center justify-center q-pa-md q-gutter-sm fixed-center",
              staticStyle: { "z-index": "1000" },
            },
            [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
            1
          ),
          _c("div", { staticClass: "q-pa-md justify-center row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-5" },
              _vm._l(_vm.domande, function (Domanda, index) {
                return _c(
                  "div",
                  _vm._b(
                    {
                      key: index,
                      staticClass: "row",
                      on: {
                        input: function ($event) {
                          return _vm.resultDomande()
                        },
                      },
                    },
                    "div",
                    Domanda,
                    false
                  ),
                  [
                    _c("div", { staticClass: "col-md-3" }),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("QQDomanda", {
                          attrs: {
                            questionario_completo: _vm.domande,
                            domanda: Domanda,
                            visible: _vm.isDomandaVisible(Domanda),
                          },
                          on: {
                            "error-checked": function ($event) {
                              return _vm.onErrorChecked(index, $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-md-3" }),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-5" },
              [
                _c(
                  "q-list",
                  _vm._l(_vm.proposte_attive, function (proposta, index) {
                    return _c(
                      "q-item",
                      _vm._b({ key: index }, "q-item", proposta, false),
                      [
                        _c(
                          "q-item-section",
                          [
                            _vm._v(" " + _vm._s(proposta.tariffa) + " "),
                            _c(
                              "FieldSet",
                              [
                                _c("legend", [
                                  _vm._v(
                                    "Frazionamento " + _vm._s(proposta.label)
                                  ),
                                ]),
                                _c("q-option-group", {
                                  ref: "frazionamento" + index,
                                  refInFor: true,
                                  attrs: {
                                    name: "frazionamento" + index,
                                    options: _vm.lista_frazionamento(proposta),
                                  },
                                  model: {
                                    value: _vm.frazionamento[proposta.tariffa],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.frazionamento,
                                        proposta.tariffa,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "frazionamento[proposta.tariffa]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("hr"),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "INDIETRO",
                    color: "blue-grey",
                    icon: "undo",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIndietroClicked.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "AVANTI",
                    color: "blue-grey",
                    icon: "mdi-arrow-right-bold",
                    size: "md",
                    disabled: !_vm.pulsante_avanti_abilitato,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onAvantiClicked.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c(
            "q-dialog",
            {
              attrs: {
                persistent: "",
                "transition-show": "scale",
                "transition-hide": "scale",
              },
              model: {
                value: _vm.persistent,
                callback: function ($$v) {
                  _vm.persistent = $$v
                },
                expression: "persistent",
              },
            },
            [
              _c(
                "q-card",
                {
                  staticClass: "bg-teal text-white",
                  staticStyle: { width: "500px" },
                },
                [
                  _c("q-card-section", [
                    _c("div", { staticClass: "text-h6" }, [
                      _vm._v("ATTENZIONE:"),
                    ]),
                  ]),
                  _c("q-card-section", { staticClass: "q-pt-none" }, [
                    _vm._v(" " + _vm._s(_vm.DlgMessage) + " "),
                  ]),
                  _c(
                    "q-card-actions",
                    {
                      staticClass: "bg-white text-teal",
                      attrs: { align: "right" },
                    },
                    [
                      _c("q-btn", {
                        directives: [
                          { name: "close-popup", rawName: "v-close-popup" },
                        ],
                        attrs: { flat: "", label: "OK" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Completa la richiesta di emissione"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }